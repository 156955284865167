
























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { User } from '@/models';
import Popover from '@/components/Popover.vue';
import { ActionButton, ActionButtonList } from '@/components/actionbuttons';

@Component({
  components: {
    Popover,
    ActionButton,
    ActionButtonList,
  },
})
export default class UserList extends Vue {
  @Prop({ required: true })
  users: User[];

  @Prop({ type: Array, default: () => ['id', 'email', 'name'] })
  columns: string[];

  @Prop({ type: Boolean, default: false })
  actions: boolean;

  columnDefinitions = {
    id: { width: 1 },
    email: { width: 5 },
    name: { width: 3 },
    actions: { width: 1 },
  };
}
