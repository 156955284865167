


















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { User } from '@/models';
import { AccountantService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';

import UserList from '@/components/admin/UserList.vue';
import Fab from '@/components/Fab.vue';
import AccountantUserModal from '@/components/modals/AccountantUserModal.vue';

@Component({
  components: {
    AccountantUserModal,
    UserList,
    Fab,
  },
})
export default class AccountantUsersPage extends Vue {
  loading: boolean = true;
  users: User[] = [];

  newUser: User | null = null;

  async created(): Promise<void> {
    await this.refresh();
  }

  async refresh(): Promise<void> {
    this.loading = true;
    try {
      this.users = await AccountantService.getUsers();
    } catch (e) {
      this.$toaster.error(this.$tc('messages.error.load.user'), unwrapError(e));
    } finally {
      this.loading = false;
    }
  }

  startNewUser(): void {
    this.newUser = new User();
  }

  async doNewUser(u: User): Promise<void> {
    try {
      await AccountantService.createUser(u);
      this.$toaster.success(this.$tc('messages.success.created.user'), u.name);
      this.newUser = null;
      await this.refresh();
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.create.user'),
        unwrapError(e),
      );
    }
  }

  async doDelete(u: User): Promise<void> {
    try {
      await AccountantService.deleteUser(u.id);
      this.$toaster.success(this.$tc('messages.success.delete.user'), u.name);
      await this.refresh();
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.delete.user'),
        unwrapError(e),
      );
    }
  }
}
