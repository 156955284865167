































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { User } from '@/models';
import { validate, ValidationError } from 'class-validator';
import ErrorList from '@/components/ErrorList.vue';
import SearchInput from '@/components/form/SearchInput.vue';
import { supportedUserLanguages } from '@/plugins/i18n';

@Component({
  components: {
    ErrorList,
    SearchInput,
  },
})
export default class InviteUserModal extends Vue {
  @Prop({ default: () => new User() })
  user: User;

  @Prop({ required: true })
  handler: (user) => Promise<void>;

  errs: ValidationError[] = [];
  languages = supportedUserLanguages;

  async onSubmit(): Promise<void> {
    this.errs = await validate(this.user);

    // This is an accountant, we ignore the role
    const roleError = this.errs.findIndex((v) => v.property === 'role');
    if (roleError !== -1) {
      this.errs.splice(roleError, 1);
    }

    if (this.errs.length > 0) {
      return;
    }

    // Quick and dirty hack for password confirm
    if (this.user.password !== this.user.confirmPassword) {
      const err = new ValidationError();
      err.constraints = { confirmPassword: 'confirmation must equal password' };
      this.errs = [err];
      return;
    }

    await this.handler(this.user);
    this.$emit('saved', this.user);
  }
}
