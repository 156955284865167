var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-list"},[_c('div',{staticClass:"fl-row tni-row--header"},[_vm._l((_vm.columns),function(column,idx){
var _obj;
return _c('div',{directives:[{name:"t",rawName:"v-t",value:('customer.' + column),expression:"'customer.' + column"}],key:idx,class:( _obj = {
        'fl-zero': true
      }, _obj['fl-grow-' + _vm.columnDefinitions[column].width] = true, _obj )})}),(_vm.actions)?_c('div',{class:( _obj = {
        'fl-zero': true
      }, _obj['fl-grow-' + _vm.columnDefinitions['actions'].width] = true, _obj )}):_vm._e()],2),_vm._l((_vm.users),function(user){
      var _obj;
return _c('div',{key:user.id,staticClass:"tni-row fl-row"},[_vm._l((_vm.columns),function(column,idx){
      var _obj;
return _c('div',{key:idx,class:( _obj = {
        'fl-zero': true
      }, _obj['fl-grow-' + _vm.columnDefinitions[column].width] = true, _obj ),domProps:{"textContent":_vm._s(user[column])}})}),(_vm.actions)?_c('div',{class:( _obj = {
        'fl-zero': true
      }, _obj['fl-grow-' + _vm.columnDefinitions['actions'].width] = true, _obj )},[_c('popover',{staticClass:"icon-button",attrs:{"position":"bottom_left"}},[_c('template',{slot:"face"},[_c('a',[_c('i',{staticClass:"material-icons"},[_vm._v("more_vert")])])]),_c('action-button-list',[_c('action-button',{attrs:{"icon":"delete"},on:{"click":function($event){return _vm.$emit('delete', user)}}},[_vm._v(_vm._s(_vm.$t('settings.users.action.delete')))])],1)],2)],1):_vm._e()],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }